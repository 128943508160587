import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FormContact from '../components/FormContact';

import ThemeContext from '../context/theme-context';

class Contact extends React.Component {
    componentDidMount() {
        const { closeMenu } = this.context;
        closeMenu();
    }

    render() {
        return (
            <Layout
                hideFooter={false}
                hideContact={true}
                hasHeaderAnimation={false}
                showNavLinks={true}
            >
                <SEO title="Contact" />
                <FormContact />
            </Layout>
        );
    }
}

Contact.contextType = ThemeContext;

export default Contact;
