import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import axios from 'axios'

const FormContact = () => {
    const intl = useIntl()

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [contactResponse, setContactResponse] = useState('')
    const [isShowingResponse, setIsShowingResponse] = useState(false)

    const [invalidField, setInvalidField] = useState({
        name: false,
        phone: false,
        email: false,
        message: false,
    })

    const validateAllFields = () => {
        let isValid = true
        const fields = {};

        [
            {
                title: 'name',
                value: name,
            },
            {
                title: 'phone',
                value: phone,
            },
            {
                title: 'email',
                value: email,
            },
            {
                title: 'message',
                value: message,
            },
        ].map(async field => {
            if (field.value === '') {
                isValid = false
            }

            fields[field.title] = field.value === ''
        })

        setInvalidField({
            ...invalidField,
            ...fields,
        })

        return isValid
    }

    const showResponse = () => {
        setIsShowingResponse(true);
        setTimeout(() => {
            setIsShowingResponse(false);
        }, 30000);
    }

    const submitForm = event => {
        event.preventDefault()

        if (!validateAllFields()) {
            setContactResponse('Alguns dados não foram preenchidos no formulário');
            showResponse()
            return
        }

        setLoading(true)

        const sendData = {
            name,
            phone,
            email,
            message,
        }

        axios
            .post('api.codeandsoul.com/', sendData, {
                headers: {
                    Origin: 'codeandsoul.com',
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                const { data } = response
                if (data) {
                    setContactResponse('Mensagem enviada com sucesso!')
                }
            })
            .catch(error => {
                setContactResponse('Erro ao enviar a mensagem!')
            })
            .finally(() => {
                setLoading(false);
                showResponse()
            })
    }

    return (
        <div className="form-contact" datanav="white">
            <div className="wrap-content">
                <div className="wrap-form">
                    <div className="wrap-title">
                        <h2 className="title">
                            {intl.formatMessage({
                                id: 'contact.title',
                            })}
                        </h2>
                        <h3 className="subtitle">
                            {intl.formatMessage({
                                id: 'contact.subtitle',
                            })}
                        </h3>
                    </div>
                    <form
                        className="form-group"
                        onSubmit={submitForm}
                        method="POST"
                    >
                        <div className="input-group">
                            <input
                                className={`
                                    input-entry
                                    ${
                                        name ? 'filled' : ''
                                    }
                                    ${
                                        invalidField['name']? 'invalid': ''
                                    }
                                `}
                                type="text"
                                id="name"
                                value={name}
                                onChange={input => setName(input.target.value)}
                                name="name"
                            />
                            <label className="input-descriptive" htmlFor="name">
                                {/*
                                    intl.formatMessage({
                                        id: ''
                                    })
                                */}
                                Nome
                            </label>
                        </div>
                        <div className="input-group">
                            <input
                                className={`
                                input-entry
                                ${
                                    email ? 'filled' : ''
                                }
                                ${
                                    invalidField['email']? 'invalid': ''
                                }
                            `}
                                type="email"
                                id="email"
                                value={email}
                                onChange={input => setEmail(input.target.value)}
                                name="email"
                            />
                            <label
                                className="input-descriptive"
                                htmlFor="email"
                            >
                                {/*
                                    intl.formatMessage({
                                        id: ''
                                    })
                                */}
                                E-mail
                            </label>
                        </div>
                        <div className="input-group">
                            <input
                                className={`
                                input-entry
                                ${
                                    phone ? 'filled' : ''
                                }
                                ${
                                    invalidField['phone']? 'invalid': ''
                                }
                            `}
                                type="text"
                                id="phone"
                                value={phone}
                                onChange={input => setPhone(input.target.value)}
                                name="phone"
                            />
                            <label
                                className="input-descriptive"
                                htmlFor="phone"
                            >
                                {/*
                                    intl.formatMessage({
                                        id: ''
                                    })
                                */}
                                Telefone
                            </label>
                        </div>
                        <div className="input-group">
                            <textarea
                                className={`
                                input-entry
                                ${
                                    message ? 'filled' : ''
                                }
                                ${
                                    invalidField['message']? 'invalid': ''
                                }
                            `}
                                id="message"
                                value={message}
                                onChange={input =>
                                    setMessage(input.target.value)
                                }
                                name="message"
                                rows="1"
                            ></textarea>
                            <label
                                className="input-descriptive"
                                htmlFor="message"
                            >
                                {/*
                                    intl.formatMessage({
                                        id: ''
                                    })
                                */}
                                Mensagem
                            </label>
                        </div>
                        <div className="contact-response">
                            {isShowingResponse ? contactResponse : ''}
                        </div>
                        <div className="wrap-button">
                            <button
                                className="button-submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <div>
                                        <span className="loading"></span>
                                    </div>
                                ) : (
                                    'Enviar'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="address">
                    <span className="contact-item">
                        São Paulo, BR <br />
                        <a href="tel:+551131361830" className="contact-text">
                            +55 11 3136-1830
                        </a>
                    </span>
                    <span className="contact-item">
                        {/*
                            intl.formatMessage({
                                id: ''
                            })
                        */}
                        Want to be part of our team? <br />
                        <a
                            href="mailto:hello@codeandsoul.com"
                            className="contact-text"
                        >
                            jobs@codeandsoul.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FormContact
